










































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class GeneralArena extends Vue {
  @Prop() level!: number;

  @Prop() preset!: { arena_level_1_hex: string; arena_level_2_hex: string; arena_level_3_hex: string };
}
