





















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
    Avatar: () => import("@/components/general/avatar.vue"),
  },
})
export default class DotCard extends Vue {
  @Prop() dot!: Dot;

  get imagePrefference(): string {
    if (this.dot.image && this.dot.image.path) {
      return this.dot.image.path;
    }

    if (this.dot.outline && this.dot.outline.meta && this.dot.outline.meta.og && this.dot.outline.meta.og["og:image"]) {
      return this.dot.outline.meta.og["og:image"];
    }

    return ``;
  }
}
