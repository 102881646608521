



































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
    Avatar: () => import("@/components/general/avatar.vue"),
  },
})
export default class HunchCard extends Vue {
  @Prop() hunch!: Hunch;
}
