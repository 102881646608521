













































































































import { Getter, Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import GeneralArena from "@/components/general/arena.vue";
import DotCard from "@/components/dots/card.vue";
import HunchCard from "@/components/hunches/card.vue";
import HunchPlaceholder from "@/components/hunches/placeholder.vue";
import DotPlaceholder from "@/components/dots/placeholder.vue";

@Component({
  components: {
    GeneralArena,
    DotCard,
    HunchCard,
    HunchPlaceholder,
    DotPlaceholder,
  },
})
export default class PageOverview extends Vue {
  @Getter("workspace/viewing") workspace!: Workspace;

  @Action("hunch/index") indexHunches!: (payload: SearchIndexPayload) => Promise<Hunch[]>;

  @Action("dot/index") indexDots!: (payload: SearchIndexPayload) => Promise<Dot[]>;

  loadHunches = false;

  hunches: Hunch[] = [];

  loadDots = false;

  level1Dots: Dot[] = [];

  level2Dots: Dot[] = [];

  level3Dots: Dot[] = [];

  dots: Dot[] = [];

  mounted() {
    this.initHunches();
    this.initDots();
  }

  private async initDots() {
    this.loadDots = true;
    if (this.workspace.is_basic) {
      await this.loadBasicDots();
    } else {
      await this.loadArenaDots();
    }
    this.loadDots = false;
  }

  private async loadArenaDots() {
    const params = { per_page: 3, s: { created_at: "desc", id: "desc" } };
    [this.level1Dots, this.level2Dots, this.level3Dots] = await Promise.all([
      this.indexDots({ ...params, q: { and: { workspace_id: this.workspace.id, arena_level: 1 } } }),
      this.indexDots({ ...params, q: { and: { workspace_id: this.workspace.id, arena_level: 2 } } }),
      this.indexDots({ ...params, q: { and: { workspace_id: this.workspace.id, arena_level: 3 } } }),
    ]);
  }

  private async loadBasicDots() {
    const params = { per_page: 9, s: { created_at: "desc", id: "desc" } };
    this.dots = await this.indexDots({ ...params, q: { and: { workspace_id: this.workspace.id } } });
  }

  private async initHunches() {
    this.loadHunches = true;

    const params = { per_page: 4, s: { created_at: "desc", id: "desc" } };

    this.hunches = await this.indexHunches({ ...params, q: { and: { workspace_id: this.workspace.id } } });

    this.loadHunches = false;
  }
}
